import clsx from "clsx";
import s from "./info-description.module.css";

type Props = {
  title: string;
  description?: any;
  className?: string;
};

export function InfoDescription(props: Props) {
  const { className, title, description } = props;
  return (
    <dl className={clsx(s.container, className)}>
      <dt className={s.title}>{title}</dt>
      <dd className={s.description}>{description ?? "–"}</dd>
    </dl>
  );
}
