import {useSearchParams} from "react-router-dom";
import {PressSecretaryInfoItem} from "./press-secretary-info-item";
import {PageTitle} from "../../ui";
import Pagination from 'rc-pagination';
import { SetStateAction, useState } from "react";

export const PressSecretaryInfo = () => {
  const [searchParams] = useSearchParams();
  const [current, setCurrent] = useState(1);
  const onPage = 5;
  const onChange = (page: SetStateAction<number>) => {
    setCurrent(page);
  };
  const ids = searchParams.get('threads') || '[]'

  return (
    <>
      <PageTitle>Готовые материалы</PageTitle>
      <div>
        {JSON.parse(ids).slice((current-1)*onPage,current*onPage).map((id: number) => <PressSecretaryInfoItem key={id} id={id} />)}
      </div>
      <Pagination
          onChange={onChange}
          current={current}
          total={JSON.parse(ids)?.length}
          pageSize={onPage}
        />
    </>
  )
}
