import type { InputHTMLAttributes } from "react";
import clsx from "clsx";
import s from "./field.module.css";
import {useState} from "react";

type Props = {
  className?: string;
  value?: string;
  icon?: "search" | void;
  type?: string;
  iconClickHandle?: (value: string) => void;
  [attr: string]: any;
} & InputHTMLAttributes<HTMLInputElement>;

export function Field({
  className,
  value,
  type = "text",
  icon,
  iconClickHandle,
  ...rest
}: Props) {
  const [textString, setTextString] = useState(value || '')

  return (
    <div className={clsx(s.container, className)}>
      <input
        type={type}
        className={clsx(s.input, { [s.hasIcon]: icon })}
        value={value}
        onChange={(event => setTextString(event.target.value))}
        {...rest}
      />
      {icon && <div className={clsx(s.icon, s[`icon-${icon}`], iconClickHandle ? s.iconClickable : null)} onClick={iconClickHandle ? () => iconClickHandle(textString || '') : undefined} />}
    </div>
  );
}
