import {
  authApi,
  authApiErrorMiddleware,
  authLocalStorageMiddleware,
  monitoringApi,
  topicsApi,
  risksApi,
  topApi,
  newsbreakApi,
  threadsApi,
  publicationsApi,
} from "../features";

export const middleware = (getDefaultMiddleware: any) =>
  getDefaultMiddleware()
    .concat(authApi.middleware)
    .concat(monitoringApi.middleware)
    .concat(topicsApi.middleware)
    .concat(risksApi.middleware)
    .concat(topApi.middleware)
    .concat(newsbreakApi.middleware)
    .concat(threadsApi.middleware)
    .concat(publicationsApi.middleware)
    .concat(authApiErrorMiddleware)
    .concat(authLocalStorageMiddleware);
