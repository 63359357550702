import { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./text-loader.module.css";

type Props = {
  className?: string;
};

const steps = [".  ", ".. ", "...", " ..", "  .", "   "];
export const TextLoader = ({ className }: Props) => {
  const [step, setStep] = useState(0);
  const icon = steps[step % steps.length];

  useEffect(() => {
    const interv = setInterval(() => setStep((step) => step + 1), 125);
    return () => clearInterval(interv);
  }, []);

  return <span className={clsx(styles.textLoader, className)}>{icon}</span>;
};
