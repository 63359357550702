import type { ReactNode } from "react";
import clsx from "clsx";
import s from "./no-data.module.css";

type Props = {
  children?: ReactNode;
  className?: string;
};

export const NoData = ({ children, className }: Props) => {
  return (
    <div className={clsx(s.noData, className)}>
      {children ? children : "Нет данных"}
    </div>
  );
};
