import type { ReactNode } from "react";
import clsx from "clsx";
import s from "./publications-chart.module.css";
import { DynamicsBarChart } from "../../../ui/bar-chart/dynamics-bar-chart";

export type Data = {
  item_date: string
  post_count: number
};

type Props = {
  data: Data[];
  className?: string;
};

export const PublicationsChart = ({ data, className }: Props) => {
  
  let chartData:any = data.map(({item_date, post_count}) => ({ name: item_date, Публикаций: post_count }));

  if(Array.isArray(data[0])){
    chartData = data.map(({...item}) => {
      return {
        name: Object.values(item)[0],
        Публикаций: Object.values(item)[1],
      }
    }) || [];
  }
  return (
    <DynamicsBarChart
      height={84}
      className={clsx(s.publicationsChart, className)}
      data={chartData}
      color="#4EC0E4"
      dataKey="value"
    />
  );
};
