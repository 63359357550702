import clsx from "clsx";
import s from "./title.module.css";

type Props = {
  className?: string;
  caption: string;
};

export function Title({ className, caption }: Props) {
  return (
    <div className={clsx(className)}>
      <span className={s.title}>{caption}</span>
    </div>
  );
}
