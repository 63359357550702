import axios from "axios";

type ApiResponseType = {
  data: any,
  error: any
}

export class ApiServices {
  static async getMaterialItem (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('thread/getthreadextended', params)
  }
  static async getWeekTrust (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('content/getweektrust', params)
  }
  static async getPostCount (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('content/getpostcount', params)
  }
  static async getRiskList (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('inpd/getRiskList', params)
  }
  static async getRiskItem (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('inpd/getRisk', params)
  }
  static async sendEvent (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('users/messageCreationRequest', params)
  }
  static async getInpdItem (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('inpd/getInpd', params)
  }
  static async getTopicRating (): Promise<ApiResponseType> {
    return await this.baseApiRequest('inpd/getTopicRating', {})
  }
  static async getMainTopics (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('stats/getMainTopics', params)
  }
  static async getTopicRatingShort (): Promise<ApiResponseType> {
    return await this.baseApiRequest('inpd/getTopicRatingShortRange', {})
  }
  static async getTopic (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('content/getTopic', params)
  }
  static async getSourceTopic (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('stats/getReferenceSourceTopics', params)
  }
  static async getThreadItem (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('thread/getthreadextended', params)
  }
  static async getThreadStats (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('stats/getThreadStats', params)
  }
  static async getOwnersTopByPostCount (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('stats/getOwnersTopByPostCount', params)
  }
  static async getAllMembers (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('thread/allmembers', params)
  }
  static async getCurrentSmi (): Promise<ApiResponseType> {
    return await this.baseApiRequest('content/currentsmi', {})
  }
  static async getRefSources (params: any): Promise<ApiResponseType> {
    return await this.baseApiRequest('inpd/getRefSources', params)
  }

  static async baseApiRequest (url: string, params: any): Promise<{ data: any, error: any}> {
    let result = null
    let error = null
    const api = !window.location.href.includes('test') ? process.env.REACT_APP_API_URL : 'https://apitest.glassen-it.com/component/socparser/'

    try {
      result = await axios.post(api + url, params,{ withCredentials: true })
    } catch (err) {
      error = err
    }

    return {
      data: result?.data,
      error,
    }
  }

}
