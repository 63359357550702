import {ChangeEvent, useEffect, useState} from "react";
import { useThreadsIogvListQuery} from "../../features";
import {ApiInfo, Field, PageTitle, Panel} from "../../ui";
import {PressSecretary} from "./press-secretary";
import s from "./press-secretaries.module.css";
import CustomSelect from "../../ui/custom-select/custom-select";
import Pagination from 'rc-pagination';
import {convertToSelectorList} from "../../utils/convert-to-selector-list";

export function PressSecretaries() {
  const [allDataLoaded, setAllDataLoaded] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [searchResponsible, setSearchResponsible] = useState('')
  const [items, setItems] = useState<any[]>([])
  const [searchIOGV, setSearchIOGV] = useState('');
  const [iogvList, setIogvList] = useState<Array<{id: string, keyword: string}>>([]);
  const [iogvListIsLoading, setIogvListIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<Array<any>>([]);
  const [current, setCurrent] = useState(1);
  const onChange = (page: any) => {
    setCurrent(page);
  };
  const onPage = 10;

  //const queryData = useGetAllPostsQuery({})
  const queryData = useThreadsIogvListQuery({ start: current > 1 ? (current-1)*onPage : 0, limit: onPage, filter:{author: searchResponsible, iogv_id: searchIOGV, status: '', title: searchString} });
  const { data, isLoading, isError, error } = queryData;

  useEffect(() => {
    if (data) {
      getAllData()
    }
  }, [data, current])

  const getAllData = async () => {
    setIogvListIsLoading(true)
    let tempResult: any[] = []
    setItems([])

    /*for (const materialItem of data?.items) {
      let extendedData = await ApiServices.getMaterialItem({thread_id: +materialItem.id!})
      if (extendedData.data.thread_id !== 0 && extendedData.data.author_iogv) {
        tempResult.push({
          ...materialItem,
          author_iogv: extendedData.data.author_iogv
        })
      }
    }
    const authorIogvList = new Set(tempResult.map((it: any) => it.author_iogv))
    setIogvList([{ id: '', keyword: 'Все' }, ...Array.from(authorIogvList).map((it: string) => ({ id: it, keyword: it }))])
    setIogvListIsLoading(false)
    let result: { authorIogv: any; ids: number[]; riskActive: number; riskSpent: number, graphData: {name: string, value: number}[] }[] = []
    const riskListResponse = await ApiServices.getRiskList({ start: 0, limit: 50, filter: {author: '', iogv_id: 0, status: ''} })
    const riskList: any[] = []
    if (riskListResponse.data.items) {
      for (const risk of riskListResponse.data.items) {
        const riskItemResponse = await ApiServices.getRiskItem({ id: risk.id })
        if (riskItemResponse.data.responsible_id.title) {
          riskList.push({
            authorIogv: riskItemResponse.data.iogv_id.title,
            status: riskItemResponse.data.status
          })
        }
      }
    }*/

    let result: { authorIogv: any; ids: number[]; riskActive: number; riskSpent: number, graphData: {name: string, value: number}[] }[] = []
    
    data?.items?.forEach((it: any) => {
      const graphData: {name: string, value: number}[] = []
      const ids = it?.threads?.map((thread:any)=>thread?.id)
      const createdDateList = it?.threads?.length <= 30 ? new Set(it?.threads?.map((thread:any)=>thread?.created_date?.slice(0,10))?.sort((a:any, b:any) => a > b ? 1 : -1))
      : new Set(it?.threads?.map((thread:any)=>thread?.created_date?.slice(0,7))?.sort((a:any, b:any) => a > b ? 1 : -1))
      createdDateList?.forEach((createdDate: any) => {
        graphData.push({
          name: createdDate,
          value: it?.threads?.filter((item: any) => it?.threads?.length <= 30 ? item?.created_date?.split(' ')[0] === createdDate : item?.created_date?.slice(0,7) === createdDate?.slice(0,7))?.length
        })
      })

      result.push({
        authorIogv: it.iogv,
        ids: ids,
        graphData,
        riskActive: it.riskActive,
        riskSpent: it.riskSpent
      })
    })

    setItems(result?.sort((a:any, b:any) => a.ids.length < b.ids.length ? 1 : -1))
    setAllDataLoaded(true)
  }

  useEffect(() => {
    if (items.length > 0) {
      setIogvList(convertToSelectorList(data.iogvs))
      setIogvListIsLoading(false)
    }
  }, [items])

  useEffect(() => {
    if (items) {
      let tempItems = [...items]
      //if (searchIOGV !== '') {
      //  tempItems = [...tempItems].filter((it: any) => it.authorIogv?.toLowerCase().includes(searchIOGV.toLowerCase()))
      //}
      setFilteredData(tempItems)
    }
  }, [searchIOGV, items])

  const searchHandle = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchString(evt.target.value)
  }

  return (
    <>
      <PageTitle>Работа Пресс-секретарей</PageTitle>
      <Panel className={s.search} padding>
      <Field className={s.searchField} icon="search" onChange={searchHandle} value={searchString} placeholder="Введите название" />
        <div className={s.field}>
          <span>ИОГВ</span>
          <CustomSelect
            items={iogvList}
            isLoading={iogvListIsLoading}
            onChange={(value) => setSearchIOGV(String(value))}
            value={searchIOGV}
            withSearch={true}
            isWide={true}
          />
        </div>
      </Panel>

      <ApiInfo isLoading={isLoading || !allDataLoaded} isError={isError} error={error} />

      {allDataLoaded && (
        <div className={s.list}>
          {filteredData.map((item: any, index: number) => (
            <PressSecretary key={item.authorIogv} number={(current-1)*onPage+(index+1)} {...item} />
          ))}
          <Pagination
            onChange={onChange}
            current={current}
            total={data?.count}
            pageSize={onPage}
          />
        </div>
      )}
    </>
  );
}
