import { useNewsbreakListQuery } from "../../features";
import { Field, PageTitle, Panel, ApiInfo, TextLoader } from "../../ui";
import { NewsBreakItem } from "./newsbreakItem";
import s from "./newsbreak.module.css";
import clsx from "clsx";
import {useState, ChangeEvent, useEffect, SetStateAction} from "react";
import {ApiServices} from "../../services/api-services";
import CustomSelect from "../../ui/custom-select/custom-select";
import {convertToSelectorList} from "../../utils/convert-to-selector-list";
import Pagination from 'rc-pagination';

export function Newsbreak() {
  const [searchString, setSearchString] = useState('');
  const [searchResponsible, setSearchResponsible] = useState('');
  const [searchIOGV, setSearchIOGV] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [current, setCurrent] = useState(1);
  const onPage = 10;
  const [items, setItems] = useState<Array<any>>([]);
  const [filteredData, setFilteredData] = useState<Array<any>>([]);
  const [filterInProgress, setFilterInProgress] = useState(true);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [responsibleList, setResponsibleList] = useState<Array<{id: string, keyword: string}>>([]);
  const [responsibleListIsLoading, setResponsibleListIsLoading] = useState(true);
  const [iogvList, setIogvList] = useState<Array<{id: string, keyword: string}>>([]);
  const [iogvListIsLoading, setIogvListIsLoading] = useState(true);

  const queryData = useNewsbreakListQuery({ start: current > 1 ? (current-1)*onPage : 0, limit: onPage, filter:{responsible:searchResponsible, iogv_id: searchIOGV, status: searchStatus , title: searchString} });
  const { data, isSuccess, isLoading, isError, error } = queryData;

  const dataLoading = (isSuccess && filteredData?.length > 0) || !isLoading ? false : true;

  const onChange = (page: SetStateAction<number>) => {
    setCurrent(page);
  };

  useEffect(() => {
    getAllData()
  }, [data])

  const getAllData = async () => {
    if (data?.items) {
      const tempItems: any[] = []
      for (const item of data.items) {
        // console.log('item', item)
        const itemResponse = await ApiServices.getInpdItem({ id: item.id })
        // console.log('itemResponse', itemResponse.data)
        if (itemResponse.data) {
          tempItems.push({
            ...item,
            details: itemResponse.data
          })
        }
      }
      setItems(tempItems)
      setDataIsLoading(false)
    }
  }

  useEffect(() => {
    if (items.length > 0) {
        setResponsibleList(convertToSelectorList([...data?.responsibles]?.sort().filter(word => !word.includes("test_user") && !word.includes("string"))))
        setIogvList(convertToSelectorList(data.iogvs))
      setResponsibleListIsLoading(false)
      setIogvListIsLoading(false)
    }
  }, [data, items])

  useEffect(() => {
    setFilterInProgress(true)
    if (items) {
      let filteredItems = [...items]
      if (searchString !== '') {
        filteredItems = [...filteredItems].filter((it: any) => it.title.toLowerCase().includes(searchString.toLowerCase()))
      }
      if (searchResponsible !== '') {
        filteredItems = [...filteredItems].filter((it: any) => it.details.responsible_id.title?.toLowerCase().includes(searchResponsible.toLowerCase()))
      }
      if (searchIOGV !== '') {
        filteredItems = [...filteredItems].filter((it: any) => it.details.iogv_id.title?.toLowerCase().includes(searchIOGV.toLowerCase()))
      }
      setFilteredData(filteredItems)
    }
    setFilterInProgress(false)
  }, [searchString, searchResponsible, searchIOGV, items])

  const searchHandle = (evt: ChangeEvent<HTMLInputElement>) => {
    setSearchString(evt.target.value)
  }
  return (
    <>
      <PageTitle>Инфоповоды</PageTitle>
      <Panel className={s.search} padding>
        <Field className={s.searchField} icon="search" onChange={searchHandle} value={searchString} placeholder="Введите название" />
        <div className={s.fieldList}>
          <div className={s.field}>
            <span>Ответственный</span>
            <CustomSelect
              items={responsibleList}
              isLoading={responsibleListIsLoading}
              onChange={(value) => {
                setSearchResponsible(String(value))
                setCurrent(1)
              }}
              value={searchResponsible}
              withSearch={true}
              isWide={true}
            />
          </div>
          <div className={s.field}>
            <span>ИОГВ</span>
            <CustomSelect
              items={iogvList}
              isLoading={iogvListIsLoading}
              onChange={(value) => {
                setSearchIOGV(String(value))
                setCurrent(1)
              }}
              value={searchIOGV}
              withSearch={true}
              isWide={true}
            />
          </div>
        </div>
        <span className={s.count}>
          Всего инфоповодов:{" "}
          {isLoading ? <TextLoader /> : data?.count ?? "–"}
        </span>
      </Panel>

      <ApiInfo isLoading={dataLoading || filterInProgress || dataIsLoading} isError={isError} error={error} />

      {isSuccess && !dataIsLoading &&(
        <>
          <div className={s.list}>
            {filteredData.map((item: any, index: number) => (
              <NewsBreakItem key={item.id} number={current > 1 ? (current-1)*onPage+(index + 1) : index + 1} {...item} />
            ))}
          </div>
          <Pagination
            onChange={onChange}
            current={current}
            total={data?.count}
            pageSize={onPage}
          />
        </>
      )}
    </>
  );
}
