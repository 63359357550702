import clsx from "clsx";
import s from "./stats-panel.module.css";

type Props = {
  className?: string;
  title: string;
  count?: number;
};

export function StatsPanel({ className, title, count }: Props) {
  return (
    <div className={clsx(s.container, className)}>
      <p className={s.title}>{title}</p>
      <p className={s.count}>{count?.toLocaleString() ?? "-"}</p>
    </div>
  );
}
