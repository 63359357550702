import clsx from "clsx";
import iconMan from "./img/icon-man.png";
import s from "./man.module.css";

type Props = {
  className?: string;
  level: number;
};

export function Man({ className, level }: Props) {
  const safeLevel = level > 100 ? 100 : level < 0 ? 0 : level;
  return (
    <div className={clsx(className, s.man)}>
      <div className={s.positive}></div>
      <img className={s.icon} src={iconMan} alt="" />
      <div className={s.negative} style={{ height: `${safeLevel}%` }}></div>
    </div>
  );
}
