import type { ButtonHTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import s from "./text-button.module.css";

type Props = {
  children: ReactNode;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function TextButton({ className, children, ...rest }: Props) {
  return (
    <button type="button" className={clsx(s.textButton, className)} {...rest}>
      {children}
    </button>
  );
}
