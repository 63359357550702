import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";
import { logOut } from "./auth-slice";

export const authLocalStorageMiddleware: Middleware =
  (_: MiddlewareAPI) => (next) => (action) => {
    if (action.type === "auth/logIn") localStorage.userId = action.payload;
    if (action.type === "auth/logOut") delete localStorage.userId;
    return next(action);
  };

export const authApiErrorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      const { status } = action.payload;
      if (status === 403) dispatch(logOut());
    }
    return next(action);
  };
