import { useLayoutEffect, useEffect, useState } from "react";
import { throttle } from "throttle-debounce";

export function useScrollPercent(element = document.documentElement) {
  const [percent, setPercent] = useState(0);

  const handleScroll = throttle(150, false, () => {
    const height = element.scrollHeight - element.clientHeight;
    const newPercent = Math.round((element.scrollTop / height) * 100);
    setPercent(newPercent || 0);
  });

  useLayoutEffect(handleScroll);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return percent;
}
