const vk = { id: 1, name: "Вконтакте", code: "vk" };
const tw = { id: 2, name: "Twitter", code: "tw" };
const fb = { id: 3, name: "Facebook", code: "fb" };
const gs = { id: 4, name: "СМИ", code: "gs" };
const tg = { id: 5, name: "Telegram", code: "tg" };
const gn = { id: 6, name: "Google News", code: "gn" };
const ig = { id: 7, name: "Instagram", code: "ig" };
const yt = { id: 8, name: "YouTube", code: "yt" };
const tt = { id: 9, name: "TikTok", code: "tt" };
const ok = { id: 10, name: "Одноклассники", code: "ok" };

export const networks = {
  smi: gs,
  socials: [vk, tg, tw, yt, fb, ig],
};
