import { Tooltip, Bar, XAxis, YAxis } from "recharts";
import { BarChart } from "./bar-chart";

type Props = {
  height: number;
  data: Record<string, string | number>[];
  dataKey: string;
  color: string;
  className?: string;
};

export function DynamicsBarChart(props: Props) {
  const { data, dataKey, color, height, className } = props;
  const data2 = data.map((it: any) => {
    return {
      name: it.name?.split(' ')[1],
      Публикаций: it.Публикаций,
    }
  })
  return (
    <BarChart className={className} data={data2} height={height}>
      <Bar dataKey='Публикаций' fill={color} />
      <Tooltip />
      <XAxis dataKey="name"/>
      <YAxis />
    </BarChart>
  );
}
