import type { TrustValue } from "../../data";
import type { FilterData } from "../../features/publications";
import clsx from "clsx";
import { useState, useEffect } from "react";
import {
  mergeQueries,
  Publication,
  PublicationsFilters,
  useInfiniteQuery,
  usePublicationsQuery,
  useStatsQuery,
  useThreadsQuery,
  useRefSourcesQuery,
} from "../../features";
import { PageTitle, Panel, Field, Select, ApiInfo, NoData } from "../../ui";
import s from "./publications.module.css";
import { trustMap } from "../../data";
import { useLocation } from "react-router";
import {useSmiTopQuery, useSocTopQuery} from "../../features";
import {useSearchParams} from "react-router-dom";
import CustomSelect from "../../ui/custom-select/custom-select";

type ApiParams = {
  thread_id?: string;
  from?: string;
  to?: string;
  start?: number;
  limit?: number;
  filter?: {
    network_id?: number | number[];
    trustoption?: TrustValue;
    referenceFilter?: number[];
  };
  sort?: {
    order?: string;
    type?: string
  }
};

export function Publications() {
  const [searchParams] = useSearchParams();
  const [expanded, expandFilters] = useState(false);
  const [typeId, setTypeId] = useState<string>(
    process.env.REACT_APP_SUBJECTS_ID as string
  );
  const [referenceId, setReferenceId] = useState<string | number>("");
  const [sourse, setSourse] = useState<string>("");
  const [sourceTitle, setSourceTitle] = useState<string>();
  const [profileID, setProfileID] = useState<string>(searchParams.get("profile_id") || '');
  const [networkID, setNetworkID] = useState<string>("");
  const [sourceType, setSourceType] = useState<number>();
  const [sourcesList, setSourcesList] = useState([] as any[]);
  const statsQuery = useStatsQuery("");
  const findType = (item: any) => item.id === +typeId;
  const activeTypeItems = statsQuery.data?.find(findType)?.items ?? [];
  const [userFilter, setUserFilter] = useState([])
  const [type, setType] = useState("subject");
  const [url, setUrl] = useState(searchParams.get("it_filter") || '');
  const [searchString, setSearchString] = useState('');
  const [postsSearch, setPostsSearch] = useState([]);

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID, REACT_APP_THREAD_ID } = process.env;

  const thread_id = REACT_APP_THREAD_ID;
  const reference_id =
    type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;



  // Params are stored inside state, for easier comparison
  // inside useEffect (used inside useInfiniteQuery)
  const [apiParams, setApiParams] = useState<ApiParams>({});

  useEffect(() => {
    setApiParams({ ...apiParams, thread_id });
  }, [thread_id]);


  useEffect(() => {
    if (activeTypeItems && activeTypeItems.length > 0) {
      if (typeId === process.env.REACT_APP_SUBJECTS_ID) {
        setReferenceId(activeTypeItems[0].id)
      } else {
        setReferenceId("")
      }
    } else {
      setReferenceId("")
    }
  }, [typeId, activeTypeItems]);

  const activeTypeIds = activeTypeItems?.map((item:any)=> item.id)

  const sourcesQuery = useRefSourcesQuery({
    thread_id: thread_id,
    referenceFilter: referenceId ? [+referenceId] : activeTypeIds,
     network_id: [1, 2, 3, 4, 5, 7, 8],
    start: 0,
    limit: 10000
  });

  useEffect(() => {
    if (sourcesQuery.data) {
      setSourcesList(sourcesQuery.data.items || [])
      checkSelectedSource(sourcesQuery.data.items)
    }
  }, [sourcesQuery])

  useEffect(() => {
    if (referenceId) {
      setApiParams((params) => ({
        ...params,
        filter: {
          ...params.filter,
          referenceFilter: referenceId ? [+referenceId] : activeTypeIds,
          profile_id: profileID ? profileID : undefined,
        },
      }));
    }
  }, [referenceId]);

  useEffect(() => {
    if (profileID) {
      setApiParams((params) => ({
        ...params,
        filter: {
          ...params.filter,
          referenceFilter: referenceId ? [+referenceId] : activeTypeIds,
          profile_id: profileID ? profileID : undefined,
          network_id: networkID ? [+networkID] : undefined,
          source_type: sourceType &&  sourceType>0 ? sourceType : undefined,
        },
      }));
    }
  }, [profileID]);

  const { items: posts, query } = useInfiniteQuery(
    usePublicationsQuery,
    apiParams,
    (res: any) => res.data?.posts,
    !apiParams.thread_id
  );
  const allQueries = mergeQueries(query);
  const { isFetching, isError, error } = allQueries;

  const searchHandle = (value: string) => {
    setSearchString(value)
      let currentItems = []
      if (value !== '') {

        currentItems = posts.filter((it: { text: string, title: string }) => it.text.toLowerCase().includes(value.toLowerCase()) || it.title.toLowerCase().includes(value.toLowerCase()))
      }
      console.log("ttttttt", currentItems)
      setPostsSearch(currentItems)
    }


  const checkSelectedSource = (list: any[]) => {
    if (url) {
      const selectedSource = list.find((it: any) => it.url === url)
      if (selectedSource) {
        setProfileID(selectedSource.profile_id)
        setNetworkID(selectedSource.network_id)
        setSourceTitle(selectedSource.title)
      }
    }
  }

  const changeSourse = (value:any) => {
    if (value !== '') {
      setSourse(value)
      setProfileID(value.split(',')[1])
      setNetworkID(value.split(',')[2])
      setSourceTitle(value.split(',')[3])
    }
    else{
      setSourceType(0)
      setProfileID('0')
      setNetworkID('')
      setSourceTitle('')
    }
  }

  function onFiltersUpdate(data: FilterData) {
    setApiParams((params) => ({
      ...params,
      to: data.to,
      from: data.from,
      filter: {
        ...params.filter,
        network_id: data.network_id,
        trustoption: data.trustoption,
      },
      sort: {
        order: data.order,
        type: data.type
      }
    }));
  }

  const typeIdHandle = (value: string | number): void => {
    setTypeId(String(value))
  }

  const referenceIdHandle = (value: string | number): void => {
    setReferenceId(value)
  }

  return (
    <>
      <PageTitle>Публикации</PageTitle>
      <Panel className={s.searchPanel} padding>
          <Field
            placeholder="Найти по названию"
            icon="search"
            className={s.search}
            iconClickHandle={searchHandle}
          />
          <div className={s.fieldWrap}>
          <div className={s.fieldContainer}>
            <label className={s.label} htmlFor="type">
              Тип:
            </label>
            <CustomSelect
              items={[
                { id: process.env.REACT_APP_SUBJECTS_ID!, keyword: 'Субъект' },
                { id: process.env.REACT_APP_EVENTS_ID!, keyword: 'Событие' }
              ]}
              onChange={(value) => typeIdHandle(value)}
              value={typeId}
              isWide={true}
            />
          </div>
          {typeId == "4" ?
            <div className={s.fieldContainer}>
              <label className={s.label} htmlFor="source">
                Событие:
              </label>
              <CustomSelect
                items={activeTypeItems}
                onChange={(value) => referenceIdHandle(value)}
                value={referenceId}
                isLoading={statsQuery.isLoading}
                isWide={true}
                withSearch={true}
              />
            </div>
            :
            <div className={s.fieldContainer}>
              <label className={s.label} htmlFor="source">
                Субъект:
              </label>
              <CustomSelect
                items={activeTypeItems}
                onChange={(value) => referenceIdHandle(value)}
                value={referenceId}
                isLoading={statsQuery.isLoading}
                isWide={true}
                placeholder="Выберите субъект"
                withSearch={true}
              />
            </div>
            }
          <div className={s.fieldContainer}>
            <label className={s.label} htmlFor="source">
              Источник:
            </label>
            <CustomSelect
              items={sourcesList.map((it: any) => ({ id: [it.url, it.profile_id, it.network_id, it.title].toString(), keyword: it.title}))}
              onChange={(value) => changeSourse(value)}
              value={sourse}
              isLoading={sourcesQuery.isLoading}
              isWide={true}
              placeholder="Выберите источник"
              withSearch={true}
            />
          </div>
          </div>
        </Panel>
      <div className={clsx(s.grid, { [s.expand]: expanded })}>
        <div className={clsx(s.filterArea, s.filterPublic)}>
          <PublicationsFilters
            expanded={expanded}
            onExpandToggle={expandFilters}
            onFilterChange={onFiltersUpdate}
          />
        </div>
        <div className={s.publications}>
          {!isFetching && !isError && posts.length === 0 && <NoData />}
          {postsSearch.length > 0 ?
        postsSearch.map((post: any) => (
          <Publication
            key={post.id}
            post={{
              id: post.id,
              uri: post.uri,
              text: post.text,
              title: post.title,
              smiType: post.smi_type,
              coat: post.smi_type === "federal",
              date: new Date(post.created_date),
              // @ts-ignore
              type: trustMap[post.trust.trust],
              author: {
                name: post.author,
                avatar: post.author_logo,
                url: post.author_url,
              },
              statistics: {
                people: post.attendance,
                views: post.viewed,
                likes: post.likes,
                comments: post.comments,
                reposts: post.reposts,
              },
              media: {
                images: post.images,
                video: post.video
              },
              networkName: post.network_name
            }}
            actions={{
              onCreateClick: () => console.log("onCreateClick"),
              onExcludeClick: () => console.log("onExcludeClick"),
              onReadClick: () => console.log("onReadClick"),
              onToneClick: () => console.log("onToneClick"),
            }}
          />
        ))
        :
        posts.map((post: any) => (
          <Publication
            onCreateEvent={post.url}
            key={post.id}
            post={{
              id: post.id,
              uri: post.uri,
              text: post.text,
              title: post.title,
              smiType: post.smi_type,
              coat: post.smi_type === "federal",
              date: new Date(post.created_date),
              // @ts-ignore
              type: trustMap[post.trust.trust],
              author: {
                name: post.author,
                avatar: post.author_logo,
                url: post.author_url,
              },
              statistics: {
                people: post.attendance,
                views: post.viewed,
                likes: post.likes,
                comments: post.comments,
                reposts: post.reposts,
              },
              media: {
                images: post.images,
                video: post.video
              },
              networkName: post.network_name
            }}
            actions={{
              onCreateClick: () => console.log("onCreateClick"),
              onExcludeClick: () => console.log("onExcludeClick"),
              onReadClick: () => console.log("onReadClick"),
              onToneClick: () => console.log("onToneClick"),
            }}
          />
        ))
        }
          <ApiInfo isLoading={isFetching} isError={isError} error={error} />
        </div>
      </div>
    </>
  );
}
