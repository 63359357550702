import type { PostActionsProps, PostProps } from "../../../ui";
import clsx from "clsx";
import s from "./publication.module.css";
import { Post, PostActions } from "../../../ui";

type Props = {
  post: PostProps;
  actions: PostActionsProps;
  className?: string;
  onCreateEvent?: (data: any) => any;
};

export const Publication = ({ post, actions, className, onCreateEvent }: Props) => {
  return (
    <div className={clsx(s.publication, className)}>
      <Post className={s.post} {...post} onCreateEvent={onCreateEvent} />
      {/*<PostActions className={"not-ready"} {...actions} />*/}
    </div>
  );
};
