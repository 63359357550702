import clsx from "clsx";
import s from "./material-info.module.css";
import {
  FilterData,
  mergeQueries,
  Publication,
  PublicationsFilters,
  useInfiniteQuery,
  useLastDateQuery,
  usePeriodGetQuery,
  usePublicationsQuery
} from "../../../features";
import {ApiInfo, NoData} from "../../../ui";
import {trustMap, TrustValue} from "../../../data";
import {useState} from "react";

type ApiParams = {
  thread_id?: string;
  from?: string;
  to?: string;
  start?: number;
  limit?: number;
  filter?: {
    network_id?: number | number[];
    trustoption?: TrustValue;
    referenceFilter?: number[];
    profile_id?: number;
  };
  sort?: {
    order?: string;
    type?: string
  }
};

type Props = {
  id: string
  profileId?: number
  selectedSource?: string;
  sourcesList?: any;
  sourcesListIsLoading?: boolean
}

export const MaterialInfoPublications = ({ id, selectedSource, sourcesList, sourcesListIsLoading }: Props) => {
  const periodGetQuery = usePeriodGetQuery({thread_id: String(id)});
  const lastDateQuery = useLastDateQuery({thread_id: String(id), from: periodGetQuery?.data?.periods?.retro_min, to: periodGetQuery?.data?.periods?.retro_max});
  const { data, isSuccess, isLoading } = lastDateQuery;
  const [apiParams, setApiParams] = useState<ApiParams>({ thread_id: id, from: periodGetQuery?.data?.periods?.retro_min, to: periodGetQuery?.data?.periods?.retro_max });
  const [expanded, expandFilters] = useState(false);

  const { items: posts, query } = useInfiniteQuery(
    usePublicationsQuery,
    apiParams,
    (res: any) => res.data?.posts,
    !apiParams.thread_id
  );
  const allQueries = mergeQueries(query);
  const { isFetching, isError, error } = allQueries;

  function onFiltersUpdate(data: FilterData) {
    setApiParams((params) => ({
      ...params,
      to: data.to,
      from: data.from,
      filter: {
        ...params.filter,
        network_id: data.network_id,
        trustoption: data.trustoption,
        profile_id: data.profile_id
      },
      sort: {
        order: data.order,
        type: data.type
      }
    }));
  }

  return (
    <div className={clsx(s.publicationsWrapOne, {
      [s.publicationsWrap]: expanded,
    })}>
      <div className={s.filterArea}>
        <PublicationsFilters
          expanded={expanded}
          onExpandToggle={expandFilters}
          onFilterChange={onFiltersUpdate}
          sourcesList={sourcesList}
          sourcesListIsLoading={sourcesListIsLoading}
          selectedSource={selectedSource}
          lastDate={data}
        />
      </div>

      <div className={s.publications}>
        {!isFetching && !isError && posts?.length === 0 && <NoData />}
        {posts?.map((post: any) => (
          <Publication
            key={post?.id}
            post={{
              id: post.id,
              uri: post.uri,
              text: post?.text,
              title: post?.title,
              smiType: post.smi_type,
              coat: post?.smi_type === "federal",
              date: new Date(post?.created_date),
              // @ts-ignore
              type: trustMap[post?.trust.trust],
              author: {
                name: post?.author,
                avatar: post?.author_logo,
                url: post?.author_url,
              },
              statistics: {
                people: post?.attendance,
                views: post?.viewed,
                likes: post?.likes,
                comments: post?.comments,
                reposts: post?.reposts,
              },
              media: {
                images: post.images,
                video: post.video
              },
              networkName: post.network_name
            }}
            actions={{
              onCreateClick: () => console.log("onCreateClick"),
              onExcludeClick: () => console.log("onExcludeClick"),
              onReadClick: () => console.log("onReadClick"),
              onToneClick: () => console.log("onToneClick"),
            }}
          />
        ))}
        <ApiInfo isLoading={isFetching} isError={isError} error={error} />
      </div>
    </div>
  )
}
