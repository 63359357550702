import { useEffect, useState } from "react";
import { useScrollPercent } from "./use-scroll-percent";

export function useInfiniteQuery(
  useQuery: any,
  inputParams: object,
  itemsGetter: Function,
  skip?: boolean
) {
  const limit = 20;
  const scrollPercent = useScrollPercent();
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(0);

  const params = {
    start: page * limit,
    limit,
    ...inputParams,
  };
  const query = useQuery(params, { skip });
  const queryPosts = itemsGetter(query) || [];

  useEffect(() => {
    setItems(() => []);
    setPage(() => 0);
  }, [inputParams, skip]);

  useEffect(() => {
    setItems((posts: any) => [...posts, ...queryPosts]);
  }, [query.data]);

  useEffect(() => {
    if (query.isFetching) return;
    if (queryPosts.length < limit) return;
    if (scrollPercent > 80) setPage(page + 1);
  }, [scrollPercent]);

  return { items, query };
}
