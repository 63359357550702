import {useEffect, useState} from "react";
import {ApiServices} from "../../services/api-services";
import s from "./scorecard.module.css";
import {Panel} from "../panel";
import {Title} from "../panel/title";
import {Table} from "../table";
import {BarChart} from "../bar-chart";
import {Bar, Legend, Tooltip, XAxis, YAxis} from "recharts";
import {useLastDateQuery, usePeriodGetQuery, usePostCountWeekQuery, useTrustHourlyQuery, useUnatedGraphQuery} from "../../features";
import {Loader} from "../loader";
import CustomSelect from "../custom-select/custom-select";
import {DateRangePicker, DateTuple} from "../date-range-picker";
import moment from "moment";

const tableRowNames = ['Количество публикаций', 'Количество комментариев', 'Количество репостов', 'Количество лайков', 'Количество просмотров']
const tableRowKeys = [
  {total: 'post_count', tonal: 'posts_count'},
  {total: 'comments_count', tonal: 'comments_count'},
  {total: 'reposts_count', tonal: 'reposts_count'},
  {total: 'likes_count', tonal: 'likes_count'},
  {total: 'viewed', tonal: 'viewed_count'}
]

type Props = {
  id: number,
  sourcesId?: number[]
  referenceFilter?: number[]
}

export const Scorecard = ({ id, sourcesId, referenceFilter = undefined }: Props) => {
  const [period, setPeriod] = useState<string>('custom')
  const periodGetQuery = usePeriodGetQuery({thread_id: String(id)});
  const lastDateQuery = useLastDateQuery({thread_id: String(id), from: periodGetQuery?.data?.periods?.retro_min, to: periodGetQuery?.data?.periods?.retro_max});
  const { data, isSuccess, isLoading } = lastDateQuery;
  const [customPeriod, setCustomPeriod] = useState<{from: Date | null, to: Date | null}>({
    from: new Date(),
    to: new Date(),
  })
  const [dataIsLoading, setDataIsLoading] = useState(true)
  const [graphDataIsLoading, setGraphDataIsLoading] = useState(true)
  const [currentStats, setCurrentStats] = useState<any>({})
  const [graphData, setGraphData] = useState<Array<any>>([])

  useEffect(() => {
    if (isSuccess && data && data.date != false) {
      setCustomPeriod({
        from: new Date(+(data?.date?.slice(0,4)),  +(data?.date?.slice(5,7))-1, +(data?.date?.slice(8,10))),
        to: new Date(+(data?.date?.slice(0,4)),  +(data?.date?.slice(5,7))-1, +(data?.date?.slice(8,10))),
      });
    }
  }, [data, periodGetQuery])

  useEffect(() => {
    getAllData()
  }, [period])

  useEffect(() => {
    if (customPeriod.from && customPeriod.to) {
      getAllData()
    }
  }, [customPeriod])

  const getAllData = async () => {
    setDataIsLoading(true)
    setGraphDataIsLoading(true)
    let tempStats: any = null

    let tempGraphData: any[] = []

    for (const sourceType of ['smi', 'social']) {
      const statsResponse = await ApiServices.getThreadStats({
        thread_id: id,
        type: sourceType,
        period: period !== 'custom' ? period : undefined,
        from: period === 'custom' ? moment(customPeriod.from)?.format('YYYY-MM-DD') + ' 00:00:00' : undefined,
        to: period === 'custom' ? moment(customPeriod.to)?.format('YYYY-MM-DD') + ' 23:59:59' : undefined,
        referenceFilter
      })
      if (statsResponse.data?.stats) {
        if (tempStats) {
          tableRowKeys.forEach((it: any, idx) => {
            tempStats[it.total] += statsResponse.data.stats[it.total]
            tempStats.positive[it.tonal] += statsResponse.data.stats.positive[it.tonal]
            tempStats.netural[it.tonal] += statsResponse.data.stats.netural[it.tonal]
            tempStats.negative[it.tonal] += statsResponse.data.stats.negative[it.tonal]
          })
        } else {
          tempStats = statsResponse.data.stats
        }
      }
      if (statsResponse.data?.stats?.graph) {
        if (tempGraphData.length < 1) {
          tempGraphData = [...statsResponse.data.stats.graph]
        } else {
          statsResponse.data.stats.graph.forEach((it: any, idx: number) => {
            tempGraphData[idx].post_count += it.post_count
            tempGraphData[idx].comments_count += it.comments_count
          })
        }
      }
    }
    setGraphData(tempGraphData.map((it: any) => {
      let name = it.item_date.split(' ')[1]
      if (period === 'week') {
        name = it.item_date.split('-')[2] + '-' + it.item_date.split('-')[1]
      }
      if (period === 'month') {
        name = it.item_date.split('-')[2]
      }
      if (period === 'custom') {

      }
      return {
        name: name,
        Комментарии: it.comments_count,
        Публикации: it.post_count || 0,
      }
    }))
    setCurrentStats(tempStats)
    setDataIsLoading(false)
    setGraphDataIsLoading(false)
  }

  return (
    <div className={s.tables}>
      <Panel className={s.table}>
        <div className={s.header}>
          <Title className={s.title} caption="Показатели" />
          <div className={s.selectors}>
            <CustomSelect
              items={[
                { id: 'day', keyword: 'День' },
                { id: 'week', keyword: 'Неделя' },
                { id: 'month', keyword: 'Месяц' },
                { id: 'custom', keyword: 'Период' }
              ]}
              onChange={(value) => setPeriod(String(value))}
              value={period}
              type="interval"
            />
          </div>
        </div>
        <div className={s.subHeader}>
          {period === 'custom'
            ? (
              <DateRangePicker
                className={s.subtitleDate}
                startDate={customPeriod.from}
                endDate={customPeriod.to}
                onChange={([startDate, endDate]: DateTuple) => {
                  setCustomPeriod({
                    from: startDate,
                    to: endDate
                  })
                }}
              />
            )
            : null
          }
        </div>
        {dataIsLoading
          ? (
            <div className={s.loaderContainer}>
              <Loader />
            </div>
          )
          : (
            <table className={s.topTable}>
              <thead className={s.topTableHead}>
              <tr className={s.topTableRow}>
                <td className={s.topTableColWide}>Название</td>
                <td className={s.topTableCol}>Всего</td>
                <td className={s.topTableCol}>Поз.</td>
                <td className={s.topTableCol}>Нейт.</td>
                <td className={s.topTableCol}>Негат.</td>
              </tr>
              </thead>
              <tbody className={s.topTableBody}>
              {
                currentStats
                ? tableRowKeys.map((item, idx) => (
                    <tr className={s.topTableRow} key={item.total}>
                      <td className={s.topTableColWide}>{tableRowNames[idx]}</td>
                      <td className={s.topTableCol}>
                        {currentStats[item.total]}
                      </td>
                      <td className={s.topTableCol}>{currentStats.positive[item.tonal]}</td>
                      <td className={s.topTableCol}>{currentStats.netural[item.tonal]}</td>
                      <td className={s.topTableCol}>{currentStats.negative[item.tonal]}</td>
                    </tr>
                  ))
                : (
                    <tr className={s.topTableEmpty}>
                      <td colSpan={5}>Нет данных</td>
                    </tr>
                )
              }
              </tbody>
            </table>
          )
        }
      </Panel>
      <Panel className={s.panel} padding>
        {graphDataIsLoading
          ? (
            <div className={s.loaderContainer}>
              <Loader />
            </div>
          )
          : (
            <BarChart data={graphData} height={299}>
              <Bar dataKey="Публикации" fill="#4EC0E4" />
              <Bar dataKey="Комментарии" fill="#8ACE21" />
              <XAxis angle={-70} dataKey="name" textAnchor="end" interval={0} height={86}/>
              <Tooltip/>
              <YAxis />
              <Legend verticalAlign="top" align="right" />
            </BarChart>
          )
        }
      </Panel>
    </div>
  )
}
