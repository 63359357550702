import clsx from "clsx";
import { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import {
  useThreadsQuery,
  useTopicsQuery,
  useRefSourcesQuery,
  useSmiQuery,
  mergeQueries,
  useMainTopicQuery,
  useStatsQuery,
} from "../../features";
import { useAppSelector } from "../../redux";
import {
  Button,
  PageTitle,
  Panel,
  ApiInfo,
  NoData,
  Topic,
  Field,
  Select,
  DateRangePicker,
  Checkbox,
} from "../../ui";
import s from "./trending.module.css";
import CustomSelect from "../../ui/custom-select/custom-select";
import moment from "moment";
import { ApiServices } from "../../services/api-services";

export const TrendingSbj = () => {
  type SateType = "subject" | "event" | "smi" | "main";
  type PeriodType = "day" | "week" | "month" | "custom";
  const [type, setType] = useState<SateType>("subject");
  const [period, setPeriod] = useState<PeriodType>("day");
  const [mainItems, setMainItems] = useState([] as any[]);
  const [precached, setPrecached] = useState<boolean>(true);
  const [currentSourceId, setCurrentSourceId] = useState<string>("");
  const [sourse, setSourse] = useState<string>("");
  const [listSourse, setListSourse] = useState([]);
  const [itemFederal, setItemFederal] = useState([]);
  const [profileID, setProfileID] = useState<string>("");
  const [networkID, setNetworkID] = useState<string>("");
  const [userFilter, setUserFilter] =
    useState<{ network_id: number; profile_id: string } | null>(null);
  const [items, setItems] = useState<Array<any>>([])
  const [detailedItems, setDetailedItems] = useState<Array<any>>([])
  const [dataIsLoading, setDataIsLoading] = useState(true)
  const [sourcesList, setSourcesList] = useState([] as any[]);
  const [sourcesListIsLoading, setSourcesListIsLoading] = useState(false);

  const { REACT_APP_EVENTS_ID, REACT_APP_SUBJECTS_ID, REACT_APP_THREAD_ID } =
    process.env;

  const thread_id = +REACT_APP_THREAD_ID!;
  const reference_id =
    type === "event" ? REACT_APP_EVENTS_ID : REACT_APP_SUBJECTS_ID;

  const user_id = useAppSelector((state) => state.auth.userId);
  const [referenceIds, setReferenceIds] = useState<string | number>("");
  const statsQuery = useStatsQuery("");
  const [typeId, setTypeId] = useState<string>(
    process.env.REACT_APP_SUBJECTS_ID as string
  );
  const findType = (item: any) => item.id === +typeId;
  const activeTypeItems = statsQuery.data?.find(findType)?.items ?? [];
  const [customPeriod, setCustomPeriod] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: new Date(),
    to: new Date(),
  });

  // const sourcesQuery = useRefSourcesQuery({
  //   thread_id: thread_id,
  //   referenceFilter: [referenceIds],
  //   network_id: type == "main" ? [1, 2, 3, 4, 5, 7, 8, 9] : [4],
  //   start: 0,
  //   limit: 10000,
  // });
  // const sourcesList = sourcesQuery.data?.items;

  useEffect(() => {
    getSourceList()
  }, [])

  const getSourceList = async () => {
    if (referenceIds) {
      const refSourcesResponse = await ApiServices.getRefSources({
        thread_id: thread_id,
        referenceFilter: referenceIds ? [referenceIds] : [],
        network_id: type == "main" ? [1, 2, 3, 4, 5, 7, 8, 9] : [4],
        start: 0,
        limit: 10000,
      })

      if (refSourcesResponse?.data?.items) {
        setSourcesList(refSourcesResponse?.data?.items)
      }
      setSourcesListIsLoading(false)
    }
  }

  // I have no idea why, but we have to pass all of this
  // to the api (even if it works without)
  const wtfHardCodedParams = {
    reference_item_id: 0,
    user_id,
    from:
      period === "custom" && moment(customPeriod.to)
        ? moment(customPeriod.from)?.format("YYYY-MM-DD") + " 00:00:00"
        : undefined,
    to:
      period === "custom" && moment(customPeriod.to)
        ? moment(customPeriod.to)?.format("YYYY-MM-DD") + " 23:59:59"
        : undefined,
    user_filter: userFilter,
  };

  // ! ADD INFINITE SCROLLING
  const topicsParams = {
    type: "smi",
    start: 0,
    limit: 100,
    thread_id,
    reference_id,
    period: period !== "custom" ? period : "",
    precached,
    ...wtfHardCodedParams,
  };

  const mainSmiParams = {
    type: "smi",
    start: 0,
    limit: 100,
    thread_id,
    reference_id,
    period: period !== "custom" ? period : "",
    precached,
    ...wtfHardCodedParams,
  };

  const mainSocialParams = {
    type: "social",
    start: 0,
    limit: 3,
    thread_id,
    reference_id,
    period: period !== "custom" ? period : "",
    precached,
    ...wtfHardCodedParams,
  };

  useEffect(() => {
    if (referenceIds) {
      setDataIsLoading(true)
      getAllTopicsData();
    }

    // setListSourse([])
    // setItemFederal([])
  }, [type, referenceIds, period, customPeriod]);

  const getAllTopicsData = async () => {
    const topicsParams = {
      type: "smi",
      start: 0,
      limit: 100,
      thread_id,
      reference_id,
      period: period !== "custom" ? period : "",
      precached,
      ...wtfHardCodedParams,
    };

    const itemsResponse = await ApiServices.getSourceTopic(topicsParams)

    if (itemsResponse?.data) {
      setItems(itemsResponse.data.items)
    }
  }

  const skipParams = { skip: !thread_id || type === "smi" || type === "main" };
  const topicsQuery = useTopicsQuery(topicsParams, skipParams);

  const smiQuery = useSmiQuery("", { skip: type !== "smi" });
  const allQueries = mergeQueries(topicsQuery, smiQuery);

  console.info(`🔥 allQueries`, mainItems);

  useEffect(() => {
    setListSourse([]);
    setItemFederal([]);
  }, [type, referenceIds, period, customPeriod]);

  // const items =
  //   type === "smi"
  //     ? smiQuery.data
  //     : type === "main"
  //     ? mainItems
  //     : mainItems;
  const [filteredData, setFilteredData] = useState([] as any[]);
  const { isSuccess, isFetching, isError, error } = allQueries;
  const dataLoading = filteredData?.length > 0 || isFetching ? false : true;
  const [filterInProgress, setFilterInProgress] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [onlyFederal, setOnlyFederal] = useState(false);

  // useEffect(() => {
  //   if (isSuccess) {
  //     setDataLoading(false)
  //   }
  // }, [allQueries])

  const changeSourse = (value: any) => {
    if (value !== "") {
      setSourse(value);
      setProfileID(value);
      setNetworkID(value.split(",")[2]);
    }
  };

  const typeSubject = () => {
    setType("subject");
    setProfileID("");
    setSourse("");
    setPeriod("day");
  };
  const typeEvent = () => {
    setType("event");
    setProfileID("");
    setSourse("");
    setPeriod("day");
  };

  useEffect(() => {
    setFilterInProgress(true)
    if (detailedItems) {
      let tempItems = [...detailedItems].sort((a: any, b: any) =>
        a.total_graph[24].post_count+a.total_graph[23].post_count+a.total_graph[22].post_count+a.total_graph[21].post_count < b.total_graph[24].post_count+b.total_graph[23].post_count+b.total_graph[22].post_count+b.total_graph[21].post_count ? 1 : -1
      );

      if (searchString) {
        tempItems = [...tempItems].filter((it: { title: string }) =>
          it.title.toLowerCase().includes(searchString.toLowerCase())
        );
      }
      if (onlyFederal) {
        tempItems = [...tempItems].filter((it: { isFederal: boolean }) => it.isFederal);
        // const tempFederalList: any[] = []
        // itemFederal.forEach((it: string) => {
        //   const item = tempItems.find((item: any) => item.id === it)
        //   if (item) {
        //     tempFederalList.push(item)
        //   }
        // })
        // tempItems = [...tempFederalList]
        // tempItems = [...tempItems].filter(
        //   (it: any) => it.smi_type === "federal"
        // );
      }
      setFilteredData(tempItems);
    }
    setFilterInProgress(false)
  }, [onlyFederal, detailedItems, searchString, itemFederal]);

  useEffect(() => {
    if (activeTypeItems && activeTypeItems?.length > 0) {
      if (typeId === process.env.REACT_APP_SUBJECTS_ID) {
        setReferenceIds(activeTypeItems[0].id);
      } else {
        setReferenceIds("");
      }
    } else {
      setReferenceIds("");
    }
  }, [typeId, activeTypeItems]);

  useEffect(() => {
    setListSourse([])
    if (items.length > 0) {
      getAllTopicsItems()
    } else {
      setDetailedItems([])
      setDataIsLoading(false)
    }
  }, [items])

  const getAllTopicsItems = async () => {
    setDataIsLoading(true)
    const itemsPromises: any[] = []
    for (const item of items) {
      itemsPromises.push(ApiServices.getTopic({
        id: item.id?.length > 10 ? item.id : item.group_id,
        type: item.url === '' ? 'social' : 'smi',
        referenceFilter: [parseInt(reference_id!)],
      }))
    }
    const tempItems: any[] = []
    Promise.all(itemsPromises).then(result => {
      result.forEach((it, idx) => {
        const isFederal = it.data.items.filter((it: any) => it.smi_type === 'federal').length > 0
        tempItems.push(({
          ...items[idx],
          isFederal,
          details: it.data
        }))
      })

      if (type === 'main') {
        let listOfSourse: any = [{id : 'all', keyword : 'Любой'}];
        console.log('listOfSourse 1', listOfSourse)
        tempItems.map((item: any) => {
          console.log('item', item)
          const sources = item.details.items.map((it: { from_id: any; author: any; }) => ({
              id: it.from_id.toString(),
              keyword: it.author,
            })
          );
          sources.map((source: any) => {
            if (!listOfSourse.find((it: { id: any }) => it.id == source.id)) {
              listOfSourse.push(source)
            }
          })
        })
        console.log('listOfSourse 2', listOfSourse)

        setListSourse(listOfSourse)
      }


      setDetailedItems(tempItems)
      setDataIsLoading(false)
    })
  }

  const searchSubmit = (value: string) => {
    setSearchString(value);
  };

  const newPeriod = (value: PeriodType) => {
    setPeriod(value);
    if (value !== "custom") setPrecached(true);
    else setPrecached(false);
  };

  const federalHandle = () => {
    // setFilteredData([]);
    // setFilterInProgress(true);
    setOnlyFederal((prevState) => !prevState);
    // setFilterInProgress(false);
  };

  const referenceIdsHandle = (value: string | number): void => {
    setReferenceIds(value);
  };

  const typeIdHandle = (value: string | number): void => {
    setTypeId(String(value));
  };

  return (
    <>
      {/*<PageTitle>Быстрорастущие темы</PageTitle>*/}
      <Panel className={s.filter} padding>
        <div className={s.filterLine}>
          <Field
            placeholder="Поиск"
            icon="search"
            className={s.search}
            iconClickHandle={searchSubmit}
          />
          <Button
            className={s.tab}
            active={type === "subject"}
            onClick={() => typeSubject()}
          >
            Субъект
          </Button>
          <Button
            className={s.tab}
            active={type === "event"}
            onClick={() => typeEvent()}
          >
            Событие
          </Button>
          <Button
            className={s.tab}
            active={onlyFederal}
            onClick={federalHandle}
          >
            Федеральная повестка
          </Button>
        </div>
        <div className={s.filterLine}>
          {type !== "smi" &&
            (type == "main" ? (
              <>
                <label className={s.range}>
                  <span className={s.label}>Период</span>
                  <CustomSelect
                    items={[{ id: "day", keyword: "День" }]}
                    onChange={(value) => newPeriod(value as PeriodType)}
                    value={period}
                    type="interval"
                  />
                </label>

                {period === "custom" ? (
                  <DateRangePicker
                    className={s.subtitleDate}
                    startDate={customPeriod.from}
                    endDate={customPeriod.to}
                    onChange={([startDate, endDate]) => {
                      setCustomPeriod({
                        from: startDate,
                        to: endDate,
                      });
                    }}
                  />
                ) : null}
              </>
            ) : (
              <label className={s.range}>
                <span className={s.label}>Период</span>
                <CustomSelect
                  items={[{ id: "day", keyword: "День" }]}
                  onChange={(value) => newPeriod(value as PeriodType)}
                  value={period}
                  type="interval"
                />
              </label>
            ))}
          {type === "main" && (
            <>
              <label className={s.range}>
                <span className={s.label}>Тип:</span>
                <CustomSelect
                  items={[
                    {
                      id: process.env.REACT_APP_SUBJECTS_ID!,
                      keyword: "Субъект",
                    },
                    {
                      id: process.env.REACT_APP_EVENTS_ID!,
                      keyword: "Событие",
                    },
                  ]}
                  onChange={(value) => typeIdHandle(value)}
                  value={typeId}
                  isWide={true}
                />
              </label>
              {typeId == "4" ? (
                <label className={s.range}>
                  <span className={s.label}>Событие:</span>
                  <CustomSelect
                    items={activeTypeItems}
                    onChange={(value) => referenceIdsHandle(value)}
                    value={referenceIds}
                    isLoading={statsQuery.isLoading}
                    isWide={true}
                    withSearch={true}
                    placeholder="Выберите событие"
                  />
                </label>
              ) : (
                <label className={s.range}>
                  <span className={s.label}>Субъект:</span>
                  <CustomSelect
                    items={activeTypeItems}
                    onChange={(value) => referenceIdsHandle(value)}
                    value={referenceIds}
                    isLoading={statsQuery.isLoading}
                    isWide={true}
                    withSearch={true}
                    placeholder="Выберите субъект"
                  />
                </label>
              )}
            </>
          )}
          <label className={s.range}>
            <span className={s.label}>Источник:</span>
            <CustomSelect
              items={listSourse}
              onChange={(value) => changeSourse(value)}
              value={sourse}
              isLoading={sourcesListIsLoading}
              isWide={true}
              placeholder="Выберите источник"
              withSearch={true}
            />
          </label>
        </div>
      </Panel>

      <ApiInfo
        isLoading={dataIsLoading || filterInProgress}
        isError={isError}
        error={error}
      />
      {!dataIsLoading && !filterInProgress && filteredData?.length < 1 && <NoData />}
      {!dataIsLoading && !filterInProgress && filteredData?.length > 0 && (
        <div className={s.list}>
          {filteredData?.map((item: any, index: number) => (
            <Topic
              key={item?.id + index}
              number={index + 1}
              data={item}
              referenceId={reference_id}
              profileId={profileID}
              networkId={networkID}
            />
          ))}
        </div>
      )}
    </>
  );
};
