import type { ReactNode } from "react";
import clsx from "clsx";
import styles from "./loader.module.css";

type Props = {
  children?: ReactNode;
  className?: string;
};

export const Loader = ({ children, className }: Props) => {
  return <div className={clsx(styles.loader, className)}>{children}</div>;
};
