import clsx from "clsx";
import people from "./img/icon-people.svg";
import views from "./img/icon-views.svg";
import likes from "./img/icon-likes.svg";
import comments from "./img/icon-comments.svg";
import reposts from "./img/icon-reposts.svg";
import s from "./statistics.module.css";

export type StatisticType = {
  people?: number;
  views?: number;
  likes?: number;
  comments?: number;
  reposts?: number;
  publications?: number;
  status?: string;
};

type Props = {
  className?: string;
  data: StatisticType;
  networkName?: string;
};

const icons = { people, views, likes, comments, reposts };

export function Statistics({ className, data, networkName }: Props) {
  type Entries = [keyof StatisticType, string][];
  const entries = Object.entries(icons) as any as Entries;

  return (
    <div className={clsx(className, s.statistics)}>
      {data.status && (
        <div className={s.textItem}>
          <div className={s.title}>Статус:</div>
          <div>{data.status}</div>
        </div>
      )}
      {typeof data.publications !== "undefined" && data.publications >= 0 && (
        <div className={s.textItem}>
          <div className={s.title}>Всего публикаций:</div>
          <div>{data.publications}</div>
        </div>
      )}

      {
        networkName === 'gs'
          ? (
            <div className={s.item}>
              <div className={s.imageBox}>
                <img className={s.itemImage} src={people} alt="" />
              </div>
              <p className={s.counter}>{data.people ?? "–"}</p>
            </div>
          )
          : entries.map(([name, url]) => (
            <div className={s.item} key={url}>
              <div className={s.imageBox}>
                <img className={s.itemImage} src={url} alt="" />
              </div>
              <p className={s.counter}>{data[name] ?? "–"}</p>
            </div>
          ))
      }
    </div>
  );
}
