import { Fragment } from "react";
import { useRoutes } from "react-router-dom";
import { Layout } from "./features";
import { useAppSelector } from "./redux";
import { routes } from "./routes";

function App() {
  const isAuth = useAppSelector((state) => Boolean(state.auth.userId));
  const Wrapper = isAuth ? Layout : Fragment;
  return <Wrapper>{useRoutes(routes)}</Wrapper>;
}

export default App;
