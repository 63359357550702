import type { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import {
  Login,
  NotFound,
  Instructions,
  Materials,
  Newsbreak,
  PressSecretaries,
  PressSecretaryInfo,
  Publications,
  Risks,
  Statistic,
  Topics,
  Trending,
  MaterialInfo,
  Summary,
} from "./pages";
import { RequireAuthRoute } from "./features";

const withAuth = (jsx: ReactNode) => <RequireAuthRoute children={jsx} />;

export const routes = [
  { path: "/", element: <Navigate to="statistic" /> },
  { path: "/instructions", element: withAuth(<Instructions />) },
  { path: "/materials", element: withAuth(<Materials />) },
  { path: "/materials/:id", element: withAuth(<MaterialInfo />) },
  { path: "/newsbreak", element: withAuth(<Newsbreak />) },
  { path: "/press-secretary", element: withAuth(<PressSecretaries />) },
  { path: "/press-secretary-info", element: withAuth(<PressSecretaryInfo />) },
  { path: "/publications", element: withAuth(<Publications />) },
  { path: "/risks", element: withAuth(<Risks />) },
  { path: "/statistic", element: withAuth(<Statistic />) },
  { path: "/topics", element: withAuth(<Topics />) },
  { path: "/trending", element: withAuth(<Trending />) },
  { path: "/summary", element: withAuth(<Summary />) },
  { path: "/login", element: <Login /> },
  { path: "*", element: <NotFound /> },
];
