import type { ButtonHTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import s from "./button.module.css";

type Props = {
  children: ReactNode;
  active?: boolean;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({ className, active, children, ...rest }: Props) {
  return (
    <button
      className={clsx(s.btn, className, { [s.active]: active })}
      {...rest}
    >
      {children}
    </button>
  );
}
